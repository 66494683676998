export function classNames(classes:{[className:string]: boolean}) {
    return Object.entries(classes)
        .filter(([key, value]) => value)
        .map(([key, value]) => key)
        .join(' ');
}

const pattern = '#song-';
export function parseHash(hash:string) {
    if (hash.indexOf(pattern) === 0) {
        return hash.slice(pattern.length);
    }
    return null;
}

export function getSlugPattern(slug:string) {
    return `${pattern}${slug}`;
}