const TOTAL_POSITIONS = 16;

// @ts-ignore
navigator.getUserMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia);

export enum InstrumentType {
    "instrument",
    "record"
}

export interface IInstrument {
    name: string;
    gain: number;
    buffer: AudioBuffer | null;
    type: InstrumentType
}

export interface IPosition {
    instruments: IInstrument[];
    use: boolean;
    posName: number;
}


function writeUTFBytes(view:DataView, offset:number, string:string){
    var lng = string.length;
    for (var i = 0; i < lng; i++){
        view.setUint8(offset + i, string.charCodeAt(i));
    }
}

function interleave(leftChannel:Float32Array, rightChannel:Float32Array){
    var length = leftChannel.length + rightChannel.length;
    var result = new Float32Array(length);

    var inputIndex = 0;

    for (var index = 0; index < length; ){
        result[index++] = leftChannel[inputIndex];
        result[index++] = rightChannel[inputIndex];
        inputIndex++;
    }
    return result;
}

function mergeBuffers(channelBuffer : Float32Array[], recordingLength: number){
    var result = new Float32Array(recordingLength);
    var offset = 0;
    var lng = channelBuffer.length;
    for (var i = 0; i < lng; i++){
        var buffer = channelBuffer[i];
        result.set(buffer, offset);
        offset += buffer.length;
    }
    return result;
}

export default class DrumboxHandler {
    public positions: IPosition[] = [...Array(TOTAL_POSITIONS)].map((_, pos) => {
        return {
            instruments: [],
            use: false,
            posName: pos + 1,
        } as IPosition;
    });
    public isDirty = false;
    public isRecording = false;
    private readonly analyzer: AnalyserNode;
    private readonly scheduleAheadTime: number = 0.1;
    private readonly lookahead: number = 100;
    private audioContext: AudioContext;
    private currentNote: number = -1;
    private nextNoteTime: number = 0.0;
    private currentPosition: number = 1;
    private notesInQueue: { note: number, time: number }[] = [];
    private timerID: number = 0;
    private lastId: number = 0;
    private instrumentList = [
        'kick-1',
        'sine',
        'bel-1',
        'bel-2',
        'bongo-1',
        'bongo-2',
        'campana',
        'cencerro-1',
        'chabel',
        'chekere-1',
        'chekere-2',
        'chekere-3',
        'clave',
        'conga-1',
        'conga-2',
        'cowbell-2',
        'cowbell-3',
        'cowbell',
        'guiro',
        'hat-1',
        'hat-2',
        'hat-3',
        'jamblock',
        'palillo',
        'timbale-2',
        'timbale',
        'tumba-2',
        'tumba',
    ];
    public instruments: IInstrument[] = [{
        name: 'Record',
        gain: 1,
        buffer: null,
        type: InstrumentType.record,
    }].concat(this.instrumentList.map((name) => ({
        name,
        gain: 1,
        buffer: null,
        type: InstrumentType.instrument,
    })));
    private recordingLength: number = 0;
    private leftchannel: Float32Array[] = [];
    private rightchannel: Float32Array[] = [];

    constructor() {
        this.draw = this.draw.bind(this);
        this.scheduler = this.scheduler.bind(this);
        this.audioContext = new AudioContext();
        this.analyzer = this.audioContext.createAnalyser();
    }

    private _tempo: number = 30;

    get tempo(): number {
        return this._tempo;
    }

    private _isPlaying: boolean = false;

    get isPlaying(): boolean {
        return this._isPlaying;
    }

    start(updates: () => void) {
        this.updates = updates;
        this.updates();
    }

    resetGrid() {
        this.positions.forEach(pos => {
            pos.instruments = [];
        })
    }

    scheduler() {
        while (this.nextNoteTime < this.audioContext.currentTime + this.scheduleAheadTime) {
            this.scheduleNote(this.currentPosition, this.nextNoteTime);
            this.nextNote();
        }
        if (this._isPlaying) {
            setTimeout(this.scheduler, this.lookahead);
        }
    }

    nextNote() {
        const secondsPerBeat = 60.0 / this._tempo;
        this.nextNoteTime += 0.25 * secondsPerBeat;
        this.currentPosition++;
        if (this.currentPosition >= TOTAL_POSITIONS) {
            this.currentPosition = 0;
        }
    }

    scheduleNote(beatNumber: number, time: number) {
        this.notesInQueue.push({note: beatNumber, time: time});
        this.playPositionInContext(this.currentPosition, time);
    }

    draw() {
        let currentNote = this.currentNote;
        const currentTime = this.audioContext.currentTime;
        while (this.notesInQueue.length && (this.notesInQueue[0].time < currentTime)) {
            currentNote = this.notesInQueue[0].note;
            this.notesInQueue.splice(0, 1);   // remove note from queue
        }
        if (this.currentNote !== currentNote) {
            if (this.positions[this.currentNote]) {
                this.positions[this.currentNote].use = false;
            }
            this.positions[currentNote].use = true;
            this.currentNote = currentNote;
            this.updates();
        }
        this.lastId = requestAnimationFrame(this.draw);
        if (!this._isPlaying) {
            window.clearTimeout(this.timerID);
            window.cancelAnimationFrame(this.lastId);
        }
    }

    hasInstrument(position: IPosition, instrument: IInstrument) {
        return position.instruments.indexOf(instrument) > -1;
    }

    addInstrument(position: IPosition, instrument: IInstrument, dirty: boolean = true) {
        if (this.hasInstrument(position, instrument)) {
            position.instruments = position.instruments.filter(x => x !== instrument);
        } else {
            position.instruments.push(instrument);
        }
        this.isDirty = dirty;
        this.updates();
    }

    playPositionInContext(pos: number, start: number) {
        let instruments = this.positions[pos].instruments;
        instruments.forEach(instrument => {
            this.playInstrument(instrument, start)
        })
    }

    playInstrument(instrument: IInstrument, start: number) {
        this.loadSound(instrument, (buffer: AudioBuffer) => {
            this.playSound(buffer, start, 1);
        });
    }

    loadSound(instrument: IInstrument, ready: (x: AudioBuffer) => void) {
        if (instrument.buffer) {
            return ready(instrument.buffer);
        }
        const url = 'audios/' + instrument.name + '.ogg';
        const request = new XMLHttpRequest();
        request.open('GET', url, true);
        request.responseType = 'arraybuffer';
        request.onload = () => {
            this.audioContext.decodeAudioData(request.response, (buffer: AudioBuffer) => {
                instrument.buffer = buffer;
                ready(buffer);
            }, e => {
                console.error('Error decoding audio', e);
            });
        };
        request.send();
    };

    playSound(buffer: AudioBuffer, start: number, vol: number) {
        const source = this.audioContext.createBufferSource();
        const gainNode = this.audioContext.createGain();
        gainNode.gain.value = vol;
        source.buffer = buffer;
        source.connect(gainNode);
        gainNode.connect(this.analyzer);
        this.analyzer.connect(this.audioContext.destination);
        source.start(start);
    };

    power() {
        this._isPlaying = !this._isPlaying;
        if (this._isPlaying) {
            this.audioContext.resume();
            this.nextNoteTime = this.audioContext.currentTime;
            this.scheduler();
            this.draw();
        } else {
            this.audioContext.suspend();
        }
        this.updates();
    }

    incTempo(dir: 1 | -1) {
        this._tempo += dir;
        if (this._tempo < 1) {
            this._tempo = 1;
        }
        this.isDirty = true;
        this.updates();
    }

    serialize() {
        return {
            positions: this.positions.filter(x => x.instruments.length).map(i => ({
                instruments: i.instruments.map(x => x.name),
                posName: i.posName,
            })),
            tempo: this.tempo,
        }
    }

    async save() {
        const song = this.serialize();
        let res = await fetch('/api/song', {
            method: 'POST',
            body: JSON.stringify(song),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .catch(error => console.error('Error:', error))
        this.isDirty = false;
        this.updates();
        return res;
    }

    load(slug: string) {
        fetch(`/api/song/${slug}`)
            .then(res => res.json())
            .then((response: any) => {
                this.resetGrid();
                this._tempo = response.tempo;
                response.positions.forEach((position: { posName: number, instruments: string[] }) => {
                    const pos = this.positions.find(x => x.posName === position.posName);
                    if (!pos) {
                        return;
                    }
                    position.instruments.forEach((insName: string) => {
                        const ins = this.instruments.find(x => x.name === insName);
                        if (!ins) {
                            return;
                        }
                        this.addInstrument(pos, ins, false);
                    });
                })
            });
    }

    playPreview(ins: IInstrument) {
        if (ins.type === InstrumentType.record) {
            if (this.isRecording) {
                this.stopRecording(ins)
            } else {
                this.recordPreview(ins);
            }
        } else {
            this.playInstrument(ins, this.currentPosition);
        }
    }

    recordPreview(ins: IInstrument) {

        navigator.getUserMedia({audio: true}, (stream: MediaStream) => {
            const bufferSize = 2048;
            const volume = this.audioContext.createGain();
            const audioInput = this.audioContext.createMediaStreamSource(stream);

            audioInput.connect(volume);

            const recorder = this.audioContext.createScriptProcessor(bufferSize, 2, 2);
            this.recordingLength = 0;
            this.leftchannel = [];
            this.rightchannel = [];
            this.isRecording = true;
            this.updates();
            recorder.onaudioprocess = e => {
                if (!this.isRecording) {
                    recorder.disconnect();
                    volume.disconnect();
                    audioInput.disconnect();
                    stream.getTracks().forEach(track => {
                        track.stop();
                    });
                    return;
                }
                let left = e.inputBuffer.getChannelData(1);
                let right = e.inputBuffer.getChannelData(1);
                // process Data
                this.leftchannel.push(new Float32Array(left));
                this.rightchannel.push(new Float32Array(right));
                this.recordingLength += bufferSize;
            };
            volume.connect(recorder);
            recorder.connect(this.audioContext.destination);

        }, () => {
        })
    }

    stopRecording(ins:IInstrument) {
        this.isRecording = false;
        this.updates();
        let leftBuffer = mergeBuffers ( this.leftchannel, this.recordingLength );
        let rightBuffer = mergeBuffers ( this.rightchannel, this.recordingLength );
        // we interleave both channels together
        var interleaved = interleave( leftBuffer, rightBuffer );
        console.log('interleaved', interleaved)
        // we create our wav file
        var buffer = new ArrayBuffer(44 + interleaved.length * 2);

        var view = new DataView(buffer);

        // RIFF chunk descriptor
        writeUTFBytes(view, 0, 'RIFF');
        view.setUint32(4, 44 + interleaved.length * 2, true);
        writeUTFBytes(view, 8, 'WAVE');

        // FMT sub-chunk
        writeUTFBytes(view, 12, 'fmt ');
        view.setUint32(16, 16, true);
        view.setUint16(20, 1, true);

        // stereo (2 channels)
        view.setUint16(22, 2, true);
        view.setUint32(24, 44100, true);
        view.setUint32(28, 44100 * 4, true);
        view.setUint16(32, 4, true);
        view.setUint16(34, 16, true);

        // data sub-chunk
        writeUTFBytes(view, 36, 'data');
        view.setUint32(40, interleaved.length * 2, true);

        // write the PCM samples
        var lng = interleaved.length;
        var index = 44;
        var volume = 1;
        for (var i = 0; i < lng; i++){
            view.setInt16(index, interleaved[i] * (0x7FFF * volume), true);
            index += 2;
        }
        console.log('Grabado view?', ins.name, view);
        this.audioContext.decodeAudioData(view.buffer, function(audioBuffer) {
            console.log('Buffer real decodificado', audioBuffer)
            ins.buffer = audioBuffer;
        });
    }

    private updates = () => {
    };
}
