import React from 'react';
import './App.css';
import Drumbox from "./Drumbox";

class App extends React.Component {

    render() {
        return (
            <div className="App">
                <h1>
                    DRUMBOX MACHINE
                    <span>REACT - WEBAUDIO API DEMO - EMILIO ASTARITA</span>
                    <span>HOLD [SHIFT] TO DRAW. [SPACE] POWER ON. [+] [-] CONTROLS TEMPO.</span>
                    <span>JIJIJIJI</span>
                </h1>

                <Drumbox/>
            </div>
        );
    }

}

export default App;
