import React from 'react';
import './Drumbox.css';
import DrumboxHandler, {IInstrument, IPosition} from "./DromboxHandler";
import InstrumentsRow from "./InstrumentsRow";
import PositionsRow from "./PositionsRow";
import Controls from './Controls';
import {getSlugPattern, parseHash} from "./utils";


class Drumbox extends React.Component {

    private handler: DrumboxHandler | null = null;
    private lastMousePosition: {
        pos: IPosition, ins: IInstrument
    } | null = null;
    private pressedShift: boolean = false;
    private mouseLocks: {
        [pos: string]: { [insName: string]: boolean }
    } = {};


    constructor(props: any) {
        super(props);
        this.mouseOver = this.mouseOver.bind(this);
        this.save = this.save.bind(this);
    }


    private mouseOver(pos: IPosition, ins: IInstrument) {
        if (!this.pressedShift) {
            this.lastMousePosition = {pos, ins};
            return;
        }
        this.tryAddInstrument(pos, ins);
    }

    private async save() {
        if (!this.handler) {
            return;
        }
        const {slug} = await this.handler.save();
        window.location.hash = getSlugPattern(slug);
    }


    private tryAddInstrument(pos: IPosition, ins: IInstrument) {
        if (this.mouseLocks[pos.posName] && this.mouseLocks[pos.posName][ins.name]) {
            return;
        }
        this.mouseLocks[pos.posName] = this.mouseLocks[pos.posName] || {};
        this.mouseLocks[pos.posName][ins.name] = true;
        setTimeout(() => {
            this.mouseLocks[pos.posName][ins.name] = false;
        }, 500);
        if (this.handler) {
            this.handler.addInstrument(pos, ins);
        }
    }


    componentDidMount() {
        const handler = this.handler = new DrumboxHandler();
        this.handler.start(() => {
            this.setState({})
        });

        const hash = parseHash(window.location.hash);
        if (hash) {
            this.handler!.load(hash);
        }

        window.addEventListener('hashchange', e => {
            if (e.newURL === e.oldURL) {
                return;
            }
            // this.handler!.load(window.location.hash.slice(1));
            // console.log(window.location.hash);
        }, false);
        document.addEventListener('keyup', e => {
            if (e.key === 'Shift') {
                this.pressedShift = false;
            }
        });
        document.addEventListener('keydown', e => {
            if (e.code === 'Space') {
                e.preventDefault();
                handler.power();
            }
            if (e.key === '+') {
                handler.incTempo(1);
            }
            if (e.key === '-') {
                handler.incTempo(-1);
            }
            if (e.key === 'Shift') {
                this.pressedShift = true;
                if (this.lastMousePosition) {
                    this.tryAddInstrument(this.lastMousePosition.pos, this.lastMousePosition.ins);
                }
            }
        });
    }

    render() {
        const handler = this.handler;
        if (!handler) {
            return null;
        }
        return (
            <div>
                <Controls handler={handler} save={this.save}/>
                <table className="drumbox">
                    <tbody>
                    <PositionsRow positions={handler.positions}/>
                    <InstrumentsRow
                        handler={handler}
                        instruments={handler.instruments}
                        positions={handler.positions}
                        mouseOver={this.mouseOver}
                    />
                    </tbody>
                </table>
            </div>

        );
    }

}

export default Drumbox;
