import React from "react";
import DrumboxHandler, {IInstrument, InstrumentType, IPosition} from "./DromboxHandler";
import PlayCell from "./PlayCell";
import {classNames} from "./utils";

interface IInstrumentsRow {
    handler: DrumboxHandler
    instruments: IInstrument[],
    positions: IPosition[],
    mouseOver: (pos:IPosition, ins:IInstrument) => void
}

export default function InstrumentsRow(props: IInstrumentsRow) {
    const {handler, instruments, positions, mouseOver} = props;
    return (
        <React.Fragment>
            {instruments.map((ins) => {
                return (<tr key={ins.name}>
                    <th className={classNames({'first': true, 'record': ins.type === InstrumentType.record})} onClick={() => { handler.playPreview(ins) } }>
                        {ins.type === InstrumentType.instrument ? ins.name : null}
                        {ins.type === InstrumentType.record && handler.isRecording ? 'STOP REC' : null}
                        {ins.type === InstrumentType.record && !handler.isRecording ? 'REC' : null}
                    </th>
                    {positions.map((pos, posIdx) => {
                        return <PlayCell
                            handler={handler}
                            pos={pos}
                            ins={ins}
                            mouseOver={mouseOver}
                            key={pos.posName}
                        />
                    })}
                </tr>)
            })}
        </React.Fragment>
    )
}