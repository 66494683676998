import React from "react";
import {IPosition} from "./DromboxHandler";

interface IPositionsRowProps {
    positions: IPosition[],
}

export default function PositionsRow(props: IPositionsRowProps) {
    const {positions} = props;
    return (
        <tr className='positions'>
            <th className='first'/>
            {positions.map((pos, idx) => {
                return (<th
                    key={idx}
                    className={pos.use ? 'use' : ''}
                >
                    {pos.posName}
                </th>)
            })}
        </tr>
    )
}