import React from "react";
import {classNames} from "./utils";
import DrumboxHandler, {IInstrument, IPosition} from "./DromboxHandler";


interface IPlayCellProps {
    handler: DrumboxHandler
    pos: IPosition,
    ins: IInstrument,
    mouseOver: (pos: IPosition, ins: IInstrument) => void
}

export default function PlayCell(props: IPlayCellProps) {
    const {handler, pos, ins, mouseOver} = props;
    return (<td
    className={
        classNames({
            'play': true,
            'hasInstrument': handler.hasInstrument(pos, ins),
            'use': pos.use,
        })}
    onClick={() => {
        handler.addInstrument(pos, ins)
    }}
    onMouseMove={() => {
        mouseOver(pos, ins)
    }}
    key={pos.posName}/>)
}