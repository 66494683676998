import {classNames, parseHash} from './utils';
import React from "react";
import DrumboxHandler from './DromboxHandler';


interface ControlsProps {
    handler: DrumboxHandler
    save: () => void
}

export default function Controls(props: ControlsProps) {
    const {handler, save} = props;

    return <div className="controls">
        <span>
            <button
                onClick={() => handler.power()}
                className={classNames({
                    power: true,
                    playing: handler.isPlaying,
                })}>POWER
            </button>
            <span className="tempo">
                            <button
                                onClick={() => handler.incTempo(1)}
                                className='tempo'>+
                            </button>
                            <label>{handler.tempo}</label>
                            <button
                                onClick={() => handler.incTempo(-1)}
                                className='tempo'>-
                            </button>
            </span>

        </span>
        <span className='version'>
            {handler.isDirty ? (
                <button onClick={() => {
                    save();
                }}>SAVE</button>
            ) : null}
            {
                !handler.isDirty && parseHash(window.location.hash) ?
                    <small>SHARE <input type='text' value={window.location.href} readOnly={true}/></small> : null
            }
        </span>
    </div>
}